import React from 'react'
import './index.css'

class Language extends React.Component {
  render () {
    return (
      <div id='Language' className='Language'>
        <div><span role="img" aria-label="chinese">🇨🇳</span> Chinese: Native</div>
        <div><span role="img" aria-label="english">🇬🇧</span> English: Fluent (written and spoken)</div>
        <div><span role="img" aria-label="swedish">🇸🇪</span> Swedish: Basic</div>
      </div>
    )
  }
}

export default Language
