import React from 'react'
import { Icon } from 'antd'

import Section from '../Section'
import Language from './Language'
import Expertise from './Expertise'
import Education from './Education'
import './index.css'

class RightColumn extends React.Component {
  render () {
    return (
      <div className='RightColumn'>
        <Section header='Contact'>
          <div className='contactCell'><a href='tel:+46700331108'><Icon type="phone" className='contactIcon' /><span className='contactLink'>+46 7 0033 1108</span></a></div>
          <div className='contactCell'><a href='mailto:danielnpu@gmail.com'><Icon type="mail" className='contactIcon' /><span className='contactLink'>danielnpu@gmail.com</span></a></div>
          <div className='contactCell'><a href='https://github.com/hex0cter'><Icon type="github" className='contactIcon' /><span className='contactLink'>github.com/hex0cter</span></a></div>
          <div className='contactCell'><a href='https://www.linkedin.com/in/xdanielhan'><Icon type="linkedin" className='contactIcon' /><span className='contactLink'>linkedin.com/in/xdanielhan</span></a></div>
          <div className='contactCell'><Icon type="environment" className='contactIcon' /><span className='contactLink'>Stockholm, Sweden</span></div>
        </Section>
        <Section header='Tech skills'>
          <Expertise />
        </Section>
        <Section header='Language'>
          <Language />
        </Section>
        <Section header='Education'>
          <Education><span role="img" aria-label="scholar">🎓</span> Master in Software Engineering, Northwestern Polytechnic University, 2005</Education>
          <Education><span role="img" aria-label="scholar">🎓</span> Bachelor in Computer Science, Northwestern Polytechnic University, 2002</Education>
        </Section>
        <Section header='Qualifications'>
          <p className='SectionDetails'>International Software Testing Qualifications Board (ISTQB) Certified.</p>
        </Section>
      </div>
    )
  }
}

export default RightColumn
