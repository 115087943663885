import React from 'react'
import { Icon } from 'antd'
import Banner from '../Banner'
import './index.css'

class Home extends React.Component {
  render () {
    return (
      <div className='Home'>
        <div className='contentWrap'>
          <Banner />
          <div className='paragraph'>
            <p>I have over 20 years of experience in software development and testing, with more than 5 years leading and managing high-performing QA teams. I take pride in my ability to build teams from the ground up, cultivate a collaborative and results-driven culture, and support the professional growth and well-being of my team members.</p>
            <p>Throughout my career, I have successfully led cross-functional teams to reduce defect rates, enhance product quality, and streamline processes. I am skilled in implementing QA best practices and ensuring compliance with industry standards. My passion lies in driving continuous improvement, mentoring others, and delivering exceptional value through innovation and teamwork.</p>
          </div>
        </div>
        <div className='footer'>
          <div className='footerItem'>&copy; Daniel Han</div>
          <div className='contactCell footerItem'><a href='https://github.com/hex0cter'><Icon type="github" className='contactIcon' /></a></div>
        </div>
      </div>
    )
  }
}

export default Home
