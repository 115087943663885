import React from 'react'
import { Icon } from 'antd'
import Section from '../Section'
import Experience from './Experience'
import './index.css'

class LeftColumn extends React.Component {
  render () {
    return (
      <div className='LeftColumn'>
        <Section header='Profile'>
          <p className='SummaryDetails'><Icon type="caret-right" /> Experienced Quality Assurance Manager and Team Lead with over 20 years in software development and testing, including 5+ years managing high-performing QA teams. </p>
          <p className='SummaryDetails'><Icon type="caret-right" /> Skilled in implementing QA best practices for both manual tests and automated tests, streamlining processes, and ensuring compliance with industry standards. </p>
          <p className='SummaryDetails'><Icon type="caret-right" /> Demonstrated expertise in leading cross-functional teams to reduce defect rates, improve product quality, and deliver outstanding results.</p>
          <p className='SummaryDetails'><Icon type="caret-right" /> Extensive experience in building teams from the ground up, cultivating a collaborative and results-oriented culture while supporting the professional development and well-being of team members.</p>
          <p className='SummaryDetails'><Icon type="caret-right" /> Highly skilled and experienced software professional in test automation and quality assurance. Renowned for designing robust automation frameworks, exceptional problem-solving abilities, and meticulous debugging skills.</p>
        </Section>
        <Section header='Professional Experience'>
          <Experience
            from='2020'
            to='PRESENT'
            company='Scrive'
            link='https://scrive.com/'
            title='Head of QA, Team Lead'>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Managed a team of 8 QA engineers, offering guidance, mentorship, and supporting their professional growth.</div>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Led the development of automated end-to-end tests, API test, load tests, frontend tests, and mobile tests for multiple Scrive products.</div>
          </Experience>
          <Experience
            from='2015'
            to='2020'
            company='Klarna'
            link='https://www.klarna.com/'
            title='Test Automation/DevOps Engineer'>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Developed automated end-to-end test framework for Klarna payment solution.</div>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Developed various tools for manual system verification.</div>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Developed Klarna payments client.</div>
          </Experience>

          <Experience
            from='2013'
            to='2015'
            company='G&D'
            link='https://www.gi-de.com/'
            title='Test Automation Engineer'>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Implemented and maintained automated tests for NFC Enabler.</div>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Designed and implemented performance tests.</div>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Designed and implemented tools for SIM card interoperability certification.</div>
          </Experience>

          <Experience
            from='2010'
            to='2013'
            company='Amino'
            link='https://www.aminocom.com/'
            title='Software Developer'>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Designed and implemented automated test framework for Freedom set-top box.</div>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Designed and implemented an interactive D-Bus client for Amino media player.</div>
          </Experience>
          <Experience
            from='2008'
            to='2010'
            company='Ericsson'
            link='https://www.ericsson.com/'
            title='Software Developer'>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Developed and maintained Ericsson's Session Border Gateway and Media Gateway Controller for the IMS network.</div>
          </Experience>
          <Experience
            from='2004'
            to='2008'
            company='Alcatel-Lucent'
            link='https://www.al-enterprise.com/'
            title='Software Developer'>
            <div className='ExperienceDetails'><div className='rightArrow'><Icon type="right" /></div> Developed and maintained ISUP/TUP protocol in Lucent's PSTN (Public Switched Telephone Network) system.</div>
          </Experience>
        </Section>
      </div>
    )
  }
}

export default LeftColumn
