import React from 'react'
import { Icon } from 'antd'
import './index.css'

class Footer extends React.Component {
  render () {
    return (
      <div id='Footer' className='Footer'>
        <div className='FooterCell'>
          <a href='https://danielhan.dev/'>
            <Icon type="home" className='FooterIcon'/><span className='FooterLink'>danielhan.dev</span>
          </a>
        </div>
      </div>
    )
  }
}

export default Footer
